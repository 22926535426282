.MobileMenu {
    -webkit-transform: translate3d(1, 1, 0) !important;
}


.NavBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top;
    width: 100%;
    -webkit-transform: translate3d(0, 0, 0) !important;
}

.icon path {
    stroke: #005898;
}

.NavigationContainer {
    transform: translate3d(0, 0, 0);
    position: fixed;
    right: 0;
    width: 100%;
    top: 0;
}

.TitleAndLink {
    width: 100%;
}

.userName {
    background-color: #91B508;
    color: white;
    padding: 15px;
    padding-right: 25px;
    width: auto;
    position: absolute;
    font-size: 24px;
    border-radius: 0 100px 100px 0;
}

.StudentName {
    background-color: #91B508;
    color: white;
    padding: 15px;
    padding-right: 25px;
    width: auto;
    position: absolute;
    font-size: 35px;
    border-radius: 0 100px 100px 0;
}

.LeftDiv {
    display: flex;
    align-items: center;
    height: 100%;
    width: 350px;
}

.RightDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 350px;
    padding-right: 15px;
}

.LogOutBt {
    color: #464545;
    background-color: white;
    width: 100%;
    height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    padding-top: 2%;
    padding-bottom: 2%;
    font-weight: 600;
    margin-right: 15px;
}

@media (min-width:1201px) {
    .StudentGoBack {
        text-align: center;
        color: white;
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 30px;
        margin-top: -10px;
        margin-bottom: 0px;
        cursor: pointer;
        transition: 0.3s;
    }
    .StudentGoBack:hover {
        color: #ffffff96 !important;
        transition: 0.3s;
    }
    .StudentTitle {
        text-align: center;
        color: white;
        width: 100%;
        font-size: 24px;
    }
    .StudentGoBack {
        text-align: center;
        color: white;
        width: 100%;
        font-size: 16px;
        margin-top: 5px;
        cursor: pointer;
    }
    .NavBar {
        height: 125px;
    }
    .CenterDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    .CenterDivStudent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 700px;
        overflow: hidden;
    }
    .CenterDivAdmin {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    .CenterDivAdmin>div {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.RomanNumber {
    font-family: 'Roboto Slab', serif;
    letter-spacing: .1em;
    font-size: 16px;
}

@media (max-width:1200px) {
    .StudentGoBack {
        text-align: center;
        color: white;
        width: 100%;
        font-size: 35px;
        margin-top: 0px;
        margin-bottom: -5px;
        cursor: pointer;
    }
    .NavBar {
        height: 100px;
    }
    .CenterDiv {
        display: none;
    }
    .CenterDivStudent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 700px;
        overflow: hidden;
    }
    .StudentTitle {
        text-align: center;
        color: white;
        width: 100%;
        font-size: 18px;
    }
    .StudentGoBack {
        text-align: center;
        color: white;
        width: 100%;
        font-size: 16px;
        margin-top: 5px;
        cursor: pointer;
    }
    .LogOutBt {
        display: none;
    }
}

@media (max-width:600px) {
    .CenterDivStudent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 700px;
        overflow: hidden;
    }
    .StudentTitle {
        display: none;
    }
    .userName, .StudentName {
        font-size: 20px;
    }
}

.NavBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top;
    width: 100%;
    transform: translate3d(0, 0, 0);
}

.HeaderLinks {
    display: flex;
    justify-content: center;
    align-items: center;
}

.KitOnHeader img {
    width: 100px;
    margin-left: 20px;
    top: 8px;
    position: relative;
    /* -webkit-transition: 100ms;
    -ms-transition: 100ms;
    transition: 100ms;
    transition-timing-function: ease-in-out;
    animation: shake 5s cubic-bezier(.36, .07, .19, .97) both;
    animation-iteration-count: infinite; */
}

.NavigationContainer {
    transform: translate3d(0, 0, 0);
    position: fixed;
    right: 0;
    width: 100%;
    top: 0;
    z-index: 0;
}

.TitleAndLink {
    width: 100%;
}

.userName {
    background-color: #91B508;
    color: white;
    padding: 15px;
    padding-right: 25px;
    width: auto;
    position: absolute;
    font-size: 24px;
    border-radius: 0 100px 100px 0;
}

.StudentName {
    background-color: #91B508;
    color: white;
    padding: 15px;
    padding-right: 25px;
    width: auto;
    position: relative;
    font-size: 35px;
    border-radius: 0 100px 100px 0;
    text-transform: capitalize;
}

.LeftDiv {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 15px;
}

.RightDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    /* padding-right: 35px; */
}

.LogOutBt {
    color: #464545;
    background-color: white;
    width: 100%;
    height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    padding-top: 2%;
    padding-bottom: 2%;
    font-weight: 600;
    margin-right: 15px;
}

.GearIcon {
    color: white;
    font-size: 50px;
    top: -5px;
    right: 35px;
    position: relative;
}

.PopupMenuContainer {
    margin-right: 10px;
    margin-top: -10px;
}

.PopupMenuContainer>div>div {
    text-align: right;
}

.LinksContainer {
    text-align: left;
    font-size: 22px;
    font-weight: 400;
}

.PopUpLogOutContainer {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    height: 60px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    cursor: pointer;
}

.PopUpLogOut {
    background-color: #F3F3F3;
    padding: 5px;
    width: 110px;
    border: 1px solid #E5E5E5;
    border-radius: 100px;
    font-size: 20px;
    font-weight: 400;
    transition: 0.5s;
}

.PopUpLogOut:hover {
    background-color: #E5E5E5;
    transition: 0.5s;
}

.PopUpTermsContainer {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.PopUpTerms {
    font-size: 16px;
}

.LinksContainer a {
    color: #464545;
}

.LinksContainer a:hover {
    color: #464545 !important;
}

.Link {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (min-width:1201px) {
    .LeftDiv {
        width: 100%;
    }
    .RightDiv {
        width: 100%;
    }
    .CenterDivStudent {
        width: 100%;
    }
    .StudentTitle {
        text-align: center;
        color: white;
        width: 100%;
        font-size: 24px;
        margin-top: 0px;
    }
    .StudentGoBack {
        text-align: center;
        color: white;
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 30px;
        margin-top: -10px;
        margin-bottom: 0px;
        cursor: pointer;
        transition: 0.3s;
    }
    .StudentGoBack:hover {
        color: #ffffff96 !important;
        transition: 0.3s;
    }
    .NavBar {
        height: 125px;
    }
    .CenterDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    .CenterDivStudent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        /* width: 700px; */
        overflow: hidden;
    }
    .CenterDivAdmin {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 700px;
        overflow: hidden;
    }
    .CenterDivAdmin>div {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.RomanNumber {
    font-family: 'Roboto Slab', serif;
    letter-spacing: .1em;
    font-size: 16px;
}

@media (max-width:1200px) {
    .GearIcon {
        color: white;
        font-size: 50px;
        top: -5px;
        right: 0px;
        position: relative;
    }
    .KitOnHeader img {
        width: 80px;
        margin-left: 20px;
        top: 22px;
        position: relative;
    }
    .StudentGoBack {
        text-align: center;
        color: white;
        width: 100%;
        font-size: 35px;
        margin-top: 0px;
        margin-bottom: -5px;
        cursor: pointer;
    }
    .nav-link {
        color: #464545 !important;
    }
    .PopUpLogOutContainer {
        margin-top: 80px;
    }
    .NavBar {
        height: 100px;
    }
    .CenterDiv {
        display: none;
    }
    .CenterDivStudent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        /* width: 700px; */
        overflow: hidden;
    }
    .StudentTitle {
        text-align: center;
        color: white;
        width: 100%;
        font-size: 18px;
    }
    .PopupMenuContainer {
        display: none;
    }
}

@media (max-width:1200px) and (min-width:801px) {
    .HeaderLinksContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .HeaderLinks {
        width: 130px;
    }
    .LeftDiv {
        width: 100%;
    }
    .RightDiv {
        width: 100%;
    }
    .CenterDivStudent {
        width: 80%;
    }
}

@media (min-width:801px) {
    .CenterItemOutOfHeader {
        display: none;
    }
}

@media (max-width:800px) {
    .LeftDiv {
        width: 100%;
    }
    .RightDiv {
        width: 15%;
    }
    .CenterDivStudent {
        display: none;
    }
    .CenterItemOutOfHeader {
        transform: translate3d(0, 0, 0);
        position: relative;
        top: 120px;
        z-index: -20;
    }
    .StudentGoBackOutOfHeader {
        color: #003050;
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 26px;
        margin-top: -5px;
        margin-bottom: 5px;
        text-align: center;
    }
    .StudentGoBackOutOfHeade:hover {
        color: #003050 !important;
    }
    .StudentTitleOutOfHeader {
        font-size: 20px;
        text-align: center;
        color: #003050;
        margin-bottom: 20px;
        margin-top: -5px;
    }
}

@media (max-width:600px) {
    .StudentGoBack {
        display: none;
    }
    .StudentTitle {
        display: none;
    }
}