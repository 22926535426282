.Link {
    display: flex;
    padding-left: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    transition: 0.5s;
}

.Link:hover {
    background-color: #F3F3F3;
    transition: 0.5s;
    color: #464545 !important;
}

.LinkIcon {
    position: relative;
    color: #005898;
}

.LinkText a {
    color: #464545 !important;
}

.LinkText {
    color: #464545 !important;
}
.LinkText :hover {
    color: #464545 !important;
}

@media (max-width:1200px) {
    .Link {
        display: flex;
        padding-left: 30px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        transition: 0.5s;
    }
    .LinkText a {
        color: #464545 !important;
    }
    .LinkText a:hover {
        color: #464545 !important;
    }
    .LinkText {
        color: #464545 !important;
    }
    .LinkText :hover {
        color: #464545 !important;
    }
}

@media (max-width:600px) {
    .LinkText {
        color: #464545 !important;
    }
    .LinkText :hover {
        color: #464545 !important;
    }
}