.PageConatiner {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: -100;
    width: 100%;
    height: 100%;
}

.BackgroungImg {
    display: flex;
    justify-content: center;
    position: absolute;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    width: 100%;
    left: 0;
    top: -30px;
    z-index: -1000;
    -webkit-transform: translateZ(0px);
    -moz-transform: translateZ(0px);
    -o-transform: translateZ(0px);
    transform: translateZ(0px);
}

.BackgroungImg img {
    width: 100%;
    height: 100%;
}

.MobileBackgroungImg {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1000;
    -webkit-transform: translateZ(0px);
    -moz-transform: translateZ(0px);
    -o-transform: translateZ(0px);
    transform: translateZ(0px);
}

.MobileBackgroungImg img {
    width: 100%;
    align-self: flex-start;
}

input, select {
    border-radius: 5px !important;
}

.FormTitle {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: 30px;
    color: #005898;
}

.AddStudent {
    text-align: center;
    margin-top: 40px;
    cursor: pointer;
}

.AddStudentText {
    color: #A3A3A3;
    font-size: 23px;
}

.AddStudent img {
    width: 70px;
}

.ConfirmTitle {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 30px;
    color: #464545;
}

.DateformDatefiled {
    width: 50%;
    display: flex;
    color: #464545;
}

.form_item, .Dateform_item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Submitbutton {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DateformTitle {
    width: 50%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.FromInput {
    font-family: 'Signika', sans-serif;
    width: 100%;
    padding: 10px;
    border: 1px solid;
    border-radius: 5px;
    border-color: #CBCBCB;
    background-color: #F8F8F8;
    font-weight: 400;
    font-size: 16px;
    box-sizing: border-box;
}

.FormButton {
    width: 100%;
    border: none;
    padding: 10px;
    font-family: 'Signika', sans-serif;
    font-size: 18px;
    width: 100%;
    padding: 10px;
    border: 1px solid;
    border-radius: 5px;
    background-color: #005898;
    color: white;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

@media (min-width:1201px) {
    .PageConatiner {
        margin-top: 125px;
    }
}

@media (max-width:1200px) {
    .PageConatiner {
        margin-top: 100px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .Container {
        width: 725px;
    }
}

@media (min-width:1300px) {
    .Container {
        width: 1250px;
    }
}

.TableComment {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 40px;
}

.LoginSmallText {
    text-align: center;
    color: #464545;
}

.Link {
    text-align: center !important;
}

.FormContainer select {
    font-family: 'Signika', sans-serif;
    padding: 10px;
    border: 1px solid;
    border-radius: 5px;
    border-color: #CBCBCB;
    background-color: #F8F8F8;
    font-weight: 400;
    font-size: 16px;
    box-sizing: border-box;
}

@media (min-width:801px) {
    .LoginSmallText .Space {
        display: none;
    }
    .FormContainer .form_item input, .FormContainer select {
        width: 350px;
    }
    .Dateform_FlexContainer {
        display: flex;
        width: 350px;
    }
    .LoginSmallText {
        font-size: 14px;
    }
    .FormContainer {
        margin-top: 30px;
        width: 550px;
        height: auto;
        position: absolute;
        background-color: #FFFFFF;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 35px;
        padding-bottom: 35px;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        -webkit-transform: translateZ(1px);
        -moz-transform: translateZ(1px);
        -o-transform: translateZ(1px);
        transform: translateZ(1px);
    }
    .ForgotPassword {
        width: 350px;
        padding-left: 25px;
        font-size: 13px;
        margin-bottom: 10px;
    }
    .FormContainer .Submitbutton button {
        width: 350px;
        border-radius: 5px;
    }
    .LogosContainer {
        display: flex;
        align-items: center;
        width: 100%;
        height: 85px;
        justify-content: center;
    }
    .YeLogo {
        height: 80%;
        width: auto;
        padding-right: 20px;
    }
    .OnlineLogo {
        height: 80%;
        width: auto;
        padding-left: 20px;
        position: relative;
        top: -7px;
    }
}

@media (max-width:800px) {
    .LoginSmallText br {
        display: none;
    }
    .FormContainer input, .FormContainer select {
        width: 100%;
    }
    .Dateform_FlexContainer {
        display: flex;
        width: 100%;
    }
    .ForgotPassword {
        width: 100%;
        padding-left: 12px;
        font-size: 13px;
        margin-bottom: 10px;
    }
    .LoginSmallText {
        font-size: 12px;
    }
    .FormContainer {
        width: 80%;
        height: auto;
        position: absolute;
        margin-top: 30px;
        background-color: #FFFFFF;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5%;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        -webkit-transform: translateZ(1px);
        -moz-transform: translateZ(1px);
        -o-transform: translateZ(1px);
        transform: translateZ(1px);
    }
    .LogosContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    .YeLogo {
        height: 50%;
        width: 50%;
    }
    .OnlineLogo {
        height: 35%;
        width: 35%;
    }
}

@media (max-width:640px) {
    .Container {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (max-width:1260px) and (min-width:641px) {}