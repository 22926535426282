.BottomAreaFix2020 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #91B508;
    padding-bottom: 5px;
    padding-top: 5px;
}

.OpenCodeArea {
    height: 60px;
    background-color: #91B508;
    color: white;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
}

.Password {
    font-size: 24px;
}

.LessonPlanTitle {
    font-size: 24px;
    margin-top: 3px;
    margin-bottom: 5px;
}

.code {
    font-size: 20px;
}

.TitleContainer {
    position: relative;
    text-align: center;
    font-size: 0px;
}

.TopTitleBackground {
    width: 100%;
    height: 100%;
}

.Title {
    font-size: 16px;
    line-height: 18px;
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 96%;
}

.ExtraWideTitle {
    width: 94% !important;
    letter-spacing: -.01em;
}

.ModelPic {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.BottomShadow {
    bottom: 0;
}

.CodeArea {
    height: 60px;
    background-color: #8B8C89;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 12px;
    padding-left: 12px;
}

.BottomArea {
    height: 60px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    overflow: hidden;
}

.BottomAreaFlat {
    height: 60px;
    background-color: #dcddde;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    overflow: hidden;
}

.BottomArea .Number {
    /* background-color: #005898; */
    width: 35%;
    height: 200%;
    border-radius: 0px 200px 200px 0px;
    font-size: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1px;
    position: relative;
}

.BottomArea .PlayIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #91B508; */
    width: 65%;
    height: 100%;
    font-size: 32px;
}

.FaPlay {
    margin-right: 5px;
}

.LessonLinkArea {
    height: 60px;
    background-color: #8A8B87;
    color: white;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
}

.LessonLinkAreaText {
    font-size: 24px;
}

.Arrow {
    font-size: 24px;
}

.CodeArea input {
    color: #005898;
    text-align: center;
    font-size: 25px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-left: 10px;
    width: 75px;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

@media (min-width:1201px) {
    .Item {
        width: 200px;
        position: relative;
        top: 0px;
        padding-right: 10px;
        padding-left: 10px;
        cursor: pointer;
        padding-left: 20px;
        margin-bottom: 10px;
        -webkit-transition: 200ms;
        -ms-transition: 200ms;
        transition: 200ms;
        transition-timing-function: ease-in-out;
    }
    /* .Item:hover {
        position: relative;
        top: -10px;
        -webkit-transition: 200ms;
        -ms-transition: 200ms;
        transition: 200ms;
        transition-timing-function: ease-in-out;
    } */
    .Tabs {
        width: 1000px;
    }
    .FlexContainer {
        display: flex;
        justify-content: center;
        align-items: top;
        padding-top: 20px;
    }
    .ItemsContainer>div {
        display: flex;
        flex-basis: calc(20% - 40px);
        justify-content: center;
        flex-direction: column;
    }
    .ItemsContainer>div>div {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    .ItemsContainer {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 1080px;
        position: absolute;
    }
}

@media (max-width:1200px) and (min-width:871px) {
    .Item {
        width: 200px;
        position: relative;
        top: 0px;
        padding-right: 10px;
        padding-left: 10px;
        /* -webkit-transition: 200ms;
        -ms-transition: 200ms;
        transition: 200ms; */
        /* cursor: pointer; */
        /* padding-left: 20px;
        margin-bottom: 10px; */
    }
    /* .Item:hover {
        position: relative;
        top: -10px;
        -webkit-transition: 200ms;
        -ms-transition: 200ms;
        transition: 200ms;
    } */
    .FlexContainer {
        display: flex;
        justify-content: center;
        align-items: top;
        padding-top: 20px;
    }
    .ItemsContainer>div {
        display: flex;
        flex-basis: calc(33% - 40px);
        justify-content: center;
        flex-direction: column;
    }
    .ItemsContainer>div>div {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    .Tabs {
        width: 820px;
    }
    .ItemsContainer {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 900px;
        position: absolute;
    }
}

@media (max-width:871px) and (min-width:681px) {
    .Item {
        width: 200px;
        position: relative;
        top: 0px;
        padding-right: 10px;
        padding-left: 10px;
        /* -webkit-transition: 200ms;
        -ms-transition: 200ms;
        transition: 200ms; */
        /* cursor: pointer; */
        /* padding-left: 20px;
        margin-bottom: 10px; */
    }
    /* .Item:hover {
        position: relative;
        top: -10px;
        -webkit-transition: 200ms;
        -ms-transition: 200ms;
        transition: 200ms;
    } */
    .FlexContainer {
        display: flex;
        justify-content: center;
        align-items: top;
        padding-top: 20px;
    }
    .ItemsContainer>div {
        display: flex;
        flex-basis: calc(33% - 40px);
        justify-content: center;
        flex-direction: column;
    }
    .ItemsContainer>div>div {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    .Tabs {
        width: 600px;
    }
    .ItemsContainer {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 680px;
        position: absolute;
    }
}

@media (max-width:681px) {
    .OpenCodeArea {
        height: 70px;
        background-color: #91B508;
        color: white;
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
    }
    .CodeArea input {
        color: #005898;
        text-align: center;
        font-size: 40px;
        padding-top: 2px;
        padding-bottom: 2px;
        margin-left: 10px;
        width: 110px;
        height: 45px;
    }
    .CodeArea {
        height: 70px;
        background-color: #8B8C89;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 12px;
        padding-left: 12px;
        font-size: 20px;
    }
    .LessonLinkArea {
        height: 70px;
        color: white;
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
    }
    .LessonLinkAreaText {
        font-size: 30px;
    }
    .Item {
        width: 250px;
        position: relative;
        top: 0px;
        padding-right: 10px;
        padding-left: 10px;
        /* -webkit-transition: 200ms;
        -ms-transition: 200ms;
        transition: 200ms; */
        /* cursor: pointer; */
        /* padding-left: 20px;
        margin-bottom: 10px; */
    }
    /* .Item:hover {
        position: relative;
        top: -10px;
        -webkit-transition: 200ms;
        -ms-transition: 200ms;
        transition: 200ms;
    } */
    .Title {
        font-size: 22px;
        line-height: 26px;
        position: absolute;
        top: 58%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        width: 100%;
    }
    .Tabs {
        width: 90%;
    }
    .FlexContainer {
        display: flex;
        justify-content: center;
        align-items: top;
        padding-top: 20px;
    }
    .ItemsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}