
.RomanNumber {
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    letter-spacing: .1em;
    font-size: 18px;
}

.ProgramTitle {
    font-size: 20px;
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 100%;
}
