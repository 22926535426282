body {
    font-family: 'Signika', sans-serif;
}

.hidden {
    display: none !important;
}

.nav-link {
    color: white;
}

.nav-link:hover {
    color: #BAD9EF;
}

.NavBarAdmin .nav-link:hover {
    color: #fff4d1;
}

.Space {
    padding-left: 5px;
    padding-right: 5px;
}

a:visited, a:link, a:visited, a:active {
    text-decoration: none;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

a:hover {
    color: #003050;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

.BackgroundContainer {
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top;
    background-size: 100% auto;
}

.PageConatiner {
    position: absolute;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: 100vmax 100vmax;
    z-index: -100;
    width: 100%;
    height: 100%;
}

p {
    margin-bottom: 5px;
    margin-top: 5px;
}

.btn {
    width: 100%;
    border: none;
    padding: 10px;
}

.Submitbutton button {
    font-family: 'Signika', sans-serif;
    font-size: 18px;
    width: 100%;
    padding: 10px;
    border: 1px solid;
    border-radius: 100px;
    background-color: #005898;
    color: white;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

.Submitbutton button:hover {
    background-color: #003050;
    -webkit-transition: background-color 500ms linear;
    -ms-transition: background-color 500ms linear;
    transition: background-color 500ms linear;
}

.logoutBtn {
    border-radius: 100px;
}

.logoutBtn:hover {
    cursor: pointer;
    background-color: #D8D8D8;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

input {
    font-family: 'Signika', sans-serif;
    width: 100%;
    padding: 10px;
    border: 1px solid;
    border-radius: 100px;
    border-color: #CBCBCB;
    background-color: #F8F8F8;
    font-weight: 400;
    font-size: 16px;
    box-sizing: border-box;
}

.InputComment {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 14px;
}

.form_item {
    margin-bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.with_comment {
    margin-bottom: 1%;
}

.FormTitle {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 40px;
    color: #005898;
}

.FormError {
    text-align: center;
    margin-top: -25px;
    margin-bottom: 8px;
    font-size: 14px;
    color: red;
}

.FormOk {
    text-align: center;
    margin-top: -25px;
    margin-bottom: 8px;
    font-size: 14px;
    color: green;
}

.loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100px;
}

table {
    text-align: left;
    border-collapse: collapse;
    margin-top: 5px;
}

.download-table-xls-button {
    margin-top: 20px;
    padding: 2px 8px;
}

th {
    background-color: #005898;
    color: white;
    font-weight: 600;
    padding: 10px;
    border: 1px solid white;
    font-size: 16px;
}

tr {
    background-color: white;
}

tr:hover {
    background-color: #F5F5F5;
    -webkit-transition: background-color 150ms linear;
    -ms-transition: background-color 150ms linear;
    transition: background-color 150ms linear;
}

td {
    color: #464545;
    font-weight: 400;
    padding: 10px;
    border: 1px solid #ddd;
    font-size: 16px;
    max-width: 200px;
    overflow: hidden;
}

.responsiveTable {
    margin-top: 30px;
}

@media screen and (max-width: 1024px) and (min-width: 641px) {
    td, th {
        font-size: 1.5vw
    }
}

@media screen and (max-width: 40em) {
    tr {
        background-color: white;
    }
    .tdBefore {
        color: #005898;
    }
    .responsiveTable tbody tr {
        border: 1px solid #464545;
        margin-bottom: 10px;
    }
}

@media (max-width:800px) {
    .form_item {
        margin-bottom: 10%;
    }
    .with_comment {
        margin-bottom: 2%;
    }
}

@media (max-width:400px) {
    .FormError {
        font-size: 12px;
        ;
    }
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.react-datepicker-wrapper {
    width: 100%;
}

.shake {
    animation: shake .8s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

.PopupMenu_popover__3hd_Z {
    left: -220px !important;
}

.PopupLink_Link__12cta {
    padding-top: 5px;
    padding-bottom: 5px;
}

.react-tabs__tab--selected {
    background: #91B508;
    border-color: #91B508;
    color: white;
    border-radius: 5px 5px 0 0;
}

.react-tabs__tab-list {
    border-bottom: 2px solid #91B508;
    margin: 0 0 10px;
    padding: 0;
}



/* image-gallery START */

.tabContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

@import "~react-image-gallery/styles/css/image-gallery.css";
.image-gallery-icon {
    color: #BAD9EF;
}

@media (min-width:1024px) {
    .image-gallery {
        width: 45%;
    }
    .react-tabs {
        width: 45%;
    }
    .react-tabs__tab-panel .image-gallery {
        width: 100% !important;
    }
    .image-gallery-thumbnails {
        overflow: hidden;
        padding: 5px 0;
        width: 100% !important;
        background-color: white;
    }
    .fullscreen .image-gallery-thumbnails {
        overflow: hidden;
        padding: 5px 0;
        width: 100%;
    }
}

/* iPad in landscape */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .image-gallery, .react-tabs {
        width: 75% !important;
        margin-top: 10px;
    }
    .react-tabs__tab-panel .image-gallery {
        width: 100% !important;
    }
}

/* Portrait iPad Pro */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .image-gallery, .react-tabs {
        width: 75% !important;
        margin-top: 10px;
    }
    .react-tabs__tab-panel .image-gallery {
        width: 100% !important;
    }
}

/* Landscape iPad Pro */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .image-gallery, .react-tabs {
        width: 75%!important;
        margin-top: 10px;
    }
    .react-tabs__tab-panel.image-gallery {
        width: 100%!important;
    }
}

@media (max-width:1023px) {
    .image-gallery {
        width: 90%!important;
    }
    .TabsGallery {
        width: 100%;
        background-color: orangered;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .react-tabs {
        width: 90%;
    }
    .react-tabs__tab {
        font-size: 15px;
    }
    .react-tabs__tab-panel .image-gallery {
        width: 100%!important;
    }
    .image-gallery-thumbnails {
        overflow: hidden;
        padding: 5px 0;
        width: 100% !important;
        background-color: white;
    }
    .fullscreen .image-gallery-thumbnails {
        overflow: hidden;
        padding: 5px 0;
        width: 100%;
        background-color: white;
    }
    .fullscreen, .image-gallery {
        background-color: white !important;
    }
}

.image-gallery-play-button {
    display: none;
}

.image-gallery-slide {
    background-color: white;
}

.fullscreen .image-gallery-thumbnails-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-gallery-slides {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
}

.image-gallery-thumbnail {
    outline: none;
    border: 1px solid #A3A3A3;
}

/* image-gallery END */

.responsiveTable td .tdBefore {
    width: calc(55% - 20px);
}

.download-table-xls-container {
    margin-top: -10px;
    margin-bottom: 100px;
}

.download-table-xls-button {
    position: absolute;
    background-color: #91B508;
    border: none;
    color: white;
    padding-top: 8px;
    font-size: 24px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.fullscreen-modal {
    position: relative;
    z-index: 10;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    _::-webkit-full-page-media, _:future, :root, .fullscreen>div {
        width: 100%;
        height: 100%;
    }
}

.image-gallery.fullscreen-modal {
    width: 100% !important;
}

/* TermsOfUse Start */

.TermsNavLink {
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
}

.TermsNavLink a {
    color: #daf0ff;
}

@media (max-width:1200px) {
    .TermsNavLink {
        display: none;
    }
}

.FlexContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.terms h3 {
    margin-bottom: 0px;
    color: #003050;
}

.terms {
    text-align: justify;
    margin-bottom: 50px;
}

@media (min-width:1301px) {
    .terms {
        width: 800px
    }
}

@media (max-width:1300px) {
    .terms {
        width: 80%
    }
}

/* TermsOfUse END */

.rc-checkbox-checked .rc-checkbox-inner {
    border-color: #005898;
    background-color: #005898;
}

.rc-checkbox:hover .rc-checkbox-inner, .rc-checkbox-input:focus+.rc-checkbox-inner {
    border-color: #005898;
}

.rc-checkbox-inner {
    margin-right: 4px;
    width: 12px;
    height: 12px;
    border-color: #8b8c89;
}

.rc-checkbox-inner:after {
    transform: rotate(45deg);
    position: absolute;
    left: 4px;
    top: 1px;
    display: table;
    width: 3px !important;
    height: 6px !important;
    border: 2px solid #ffffff;
    border-top: 0;
    border-left: 0;
    content: ' ';
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    animation-duration: 0.3s;
    animation-name: amCheckboxOut;
}

/* FIX 2020 */

.ProgramsLoader {
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
}

.fade {
    opacity: 0;
}

.custom-control{
 
}
.slider{
    background-color: royalblue;
    height: 100px;
    display: flex;
}
.bullet{
    background-color: red;
    height: 20px;
    padding: 10px;
    margin: 10px;
}