.LoginConatiner {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0
}

.LoginSmallText {
    text-align: center;
    color: #464545;
}

a {
    color: #005898;
}

.byLogintextSpan {
    position: relative;
    /* z-index: 500000; */
    color: #464545;
}

.TermBold{
    font-weight: 700;
}
@media (min-width:801px) {
    .byLogintext {
        font-size: 12px;
    }
    .InputComment a {
        position: relative;
        z-index: 100000;
        
    }
    .InputComment {
        width: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
        margin-top: 5px;
    }
    .LoginSmallText {
        font-size: 14px;
    }
    .FormContainer {
        margin-top: 10%;
        width: 550px;
        height: auto;
        position: absolute;
        background-color: #FFFFFF;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 35px;
        padding-bottom: 35px;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .FormContainer input {
        width: 350px;
    }
    .ForgotPassword {
        width: auto;
        padding-left: 0px;
        font-size: 13px;
        /* margin-bottom: 10px; */
    }
    .FormContainer button {
        width: 150px !important;
    }
    .LogosContainer {
        display: flex;
        align-items: center;
        width: 100%;
        height: 85px;
        justify-content: center;
    }
    .YeLogo {
        height: 80%;
        width: auto;
        padding-right: 20px;
    }
    .OnlineLogo {
        height: 80%;
        width: auto;
        padding-left: 20px;
        position: relative;
        top: -7px;
    }
}

@media (max-width:800px) {
    .byLogintext {
        font-size: 13px;
        margin-top: 10px;
    }
    .InputComment {
        width: 350px;
        display: block;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        font-size: 13px;
        margin-top: 5px;
    }
    .ForgotPassword {
        width: auto;
        padding-left: 0px;
        font-size: 13px;
        margin-bottom: 0px;
    }
    .LoginSmallText {
        font-size: 12px;
    }
    .FormContainer {
        width: 75%;
        height: auto;
        position: absolute;
        margin-top: 30%;
        background-color: #FFFFFF;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5%;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .LogosContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    .YeLogo {
        height: 50%;
        width: 50%;
    }
    .OnlineLogo {
        height: 35%;
        width: 35%;
    }
}

@media (max-width:371px) {
    .LoginSmallText {
        font-size: 11px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .LoginSmallText {
        font-size: 16px;
    }
}