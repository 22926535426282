.Item:hover {
    position: relative;
    top: -10px;
    -webkit-transition: 200ms;
    -ms-transition: 200ms;
    transition: 200ms;
}

.RomanNumber {
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    letter-spacing: .1em;
    font-size: 18px;
}

.TitleContainer {
    position: relative;
    text-align: center;
    font-size: 0px;
}

.TopTitleBackground {
    width: 100%;
}

.Title {
    font-size: 20px;
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 100%;
}

.ProgramLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: #F5F5F5;
}

.BottomShadow {
    bottom: 0;
}

.ProgramLogo img {
    max-width: 250px;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 768px) {
    .FlexContainer {
        display: flex;
        justify-content: center;
        align-items: top;
        padding-top: 20px;
    }
    .Item {
        width: 350px;
        /* height: 0px; */
        position: relative;
        top: 0px;
        -webkit-transition: 200ms;
        -ms-transition: 200ms;
        transition: 200ms;
        cursor: pointer;
        padding-right: 20px;
        padding-left: 20px;
        margin-bottom: 10px;
    }
    .ItemsContainer>div {
        display: flex;
        flex-basis: calc(50% - 40px);
        justify-content: center;
        flex-direction: column;
    }
    .ItemsContainer>div>div {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    .ItemsContainer {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 770px;
        position: absolute;
    }
}

@media (max-width: 767px) {
    .ProgramLogo img {
        max-width: 250px;
    }
    .Item {
        width: 85%;
        /* height: 0px; */
        position: relative;
        top: 0px;
        -webkit-transition: 200ms;
        -ms-transition: 200ms;
        transition: 200ms;
        cursor: pointer;
        margin-bottom: 20px;
    }
    .FlexContainer {
        display: flex;
        justify-content: center;
        align-items: top;
        padding-top: 20px;
    }
    .ItemsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}