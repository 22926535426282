.BackgroungImg {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    width: 100%;
    left: 0;
    top: -30px;
    z-index: -1000;
    -webkit-transform: translateZ(0px);
    -moz-transform: translateZ(0px);
    -o-transform: translateZ(0px);
    transform: translateZ(0px);
}

.BackgroungImg img {
    width: 100%;
    height: 100%;
}

.MobileBackgroungImg {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1000;
    -webkit-transform: translateZ(0px);
    -moz-transform: translateZ(0px);
    -o-transform: translateZ(0px);
    transform: translateZ(0px);
}

.MobileBackgroungImg img {
    width: 100%;
    align-self: flex-start;
}



.PageConatiner {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: -100;
    width: 100%;
    height: 100%;
    -webkit-transform: translateZ(0px);
    -moz-transform: translateZ(0px);
    -o-transform: translateZ(0px);
    transform: translateZ(0px);
}

.GoBackLessonPlan {
    text-align: center;
    margin-top: 20px;
    margin-bottom: -10px;
    color: #003050;
    cursor: pointer;
}

.SingUpNewLink {
    height: 100%;
    width: auto;
}


.NumOfStudents {
    text-align: center;
    width: 70px;
}

.StudentsListBt {
    background-color: #91B508;
    border: none;
    padding: 10px;
    color: #FFFFFF;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}


.StudentsListBt:hover {
    background-color: #2E9627;
}

.ButtonField {
    max-width: 60px;
    text-align: center;
}

@media screen and (max-width: 40em) {
    .ButtonField {
        max-width: 100% !important;
        text-align: center;
    }
}

.FlexContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.StudentListAddUser {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
    cursor: pointer;
    font-size: 20px;
    color: #A3A3A3;
}

.Title {
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: -10px;
    color: #003050;
}

.StudentListAddUser img {
    width: 70px;
}

.excelIcon {
    width: 100px;
    cursor: pointer;
}

@media (min-width:1201px) {
    .PageConatiner {
        margin-top: 125px;
    }
}

@media (max-width:1200px) {
    .PageConatiner {
        margin-top: 100px;
    }
}

/* TABLE WIDTH */

@media (min-width:1651px) {
    .Container {
        padding-left: 5%;
        padding-right: 5%;
        width: 80%;
    }
}

@media (max-width:1650px) {
    .Container {
        padding-left: 5%;
        padding-right: 5%;
        width: 100%;
    }
}

/* ipad landscape */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .Container {
        padding-left: 2%;
        padding-right: 2%;
        width: 100%;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .Container {
        padding-left: 2%;
        padding-right: 2%;
        width: 100%;
    }
}

/* Landscape iPad Pro */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .Container {
        padding-left: 2%;
        padding-right: 2%;
        width: 100%;
    }
}

/* TABLE WIDTH */

.TableComment {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0px;
}

.LoginSmallText {
    text-align: center;
    color: #464545;
}

@media (min-width:801px) {
    .LoginSmallText {
        font-size: 14px;
    }
    .FormContainer {
        margin-top: 10%;
        width: 550px;
        height: auto;
        position: absolute;
        background-color: #FFFFFF;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 35px;
        padding-bottom: 35px;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .FormContainer input {
        width: 350px;
    }
    .ForgotPassword {
        width: 350px;
        padding-left: 25px;
        font-size: 13px;
        margin-bottom: 10px;
    }
    .FormContainer button {
        width: 150px;
    }
    .LogosContainer {
        display: flex;
        align-items: center;
        width: 100%;
        height: 85px;
        justify-content: center;
    }
    .YeLogo {
        height: 80%;
        width: auto;
        padding-right: 20px;
    }
    .OnlineLogo {
        height: 80%;
        width: auto;
        padding-left: 20px;
        position: relative;
        top: -7px;
    }
}

@media (max-width:800px) {
    .ForgotPassword {
        width: 100%;
        padding-left: 12px;
        font-size: 13px;
        margin-bottom: 10px;
    }
    .LoginSmallText {
        font-size: 12px;
    }
    .FormContainer {
        width: 65%;
        height: auto;
        position: absolute;
        margin-top: 30%;
        background-color: #FFFFFF;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5%;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .LogosContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    .YeLogo {
        height: 50%;
        width: 50%;
    }
    .OnlineLogo {
        height: 35%;
        width: 35%;
    }
}

@media (max-width:640px) {
    .Container {
        padding-left: 20px;
        padding-right: 20px;
    }
}